import { Tooltip, TooltipContent } from '@/components/atoms/Tooltip'
import { DescribedImage } from '@/components/molecules/DescribedImage'
import type { VaultForOptimizer } from '@/types'
import { getNetworkImage, getTokenImage } from '@vaultsfyi/common'

export const VaultBasics = ({ asset, network }: Pick<VaultForOptimizer, 'asset' | 'network'>) => {
  return (
    <div className="flex items-center gap-2">
      <Tooltip>
        <DescribedImage
          src={getTokenImage(asset.symbol)}
          fallback={getTokenImage('fallback')}
          alt={`${asset.symbol} token image`}
          loading="lazy"
        >
          <span className="hidden md:block">{asset.symbol}</span>
        </DescribedImage>
        <TooltipContent>
          <p>
            <b>Vault asset:</b> {asset.symbol}
          </p>
        </TooltipContent>
      </Tooltip>
      <Tooltip>
        <DescribedImage src={getNetworkImage(network)} alt={`${network} network image`} loading="lazy">
          <span className="hidden first-letter:uppercase md:block">{network}</span>
        </DescribedImage>
        <TooltipContent>
          <p>
            <b>Vault network:</b> <span className="inline-block first-letter:uppercase">{network}</span>
          </p>
        </TooltipContent>
      </Tooltip>
    </div>
  )
}
