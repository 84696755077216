import { cn } from '@/utils/cn'
import { type VariantProps, cva } from 'class-variance-authority'
import { type IconProps, iconVariants } from './IconBase'

const directionVariants = cva(cn('origin-center [transform-box:fill-box]'), {
  variants: {
    direction: {
      left: 'rotate-180',
      down: 'rotate-90',
      up: '-rotate-90',
      right: 'rotate-0',
    },
  },
  defaultVariants: {
    direction: 'right',
  },
})

export const ArrowIcon = ({
  direction = 'up',
  size = 12,
  className,
  ...props
}: IconProps & VariantProps<typeof directionVariants>) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <g className={cn(directionVariants({ direction }))}>
        <path
          d="M581.5-480 290-771.5q-14-14-13.75-33.25T290.5-838q14-14 33.25-14T357-838l304.5 305q11.5 11.5 16.75 25t5.25 28q0 14.5-5.25 28t-16.75 25l-305 305q-14 14-33 13.75t-33-14.25q-14-14-14-33.25t14-33.25l291-291Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export const ArrowCircleIcon = ({
  direction = 'up',
  size = 12,
  className,
  ...props
}: IconProps & VariantProps<typeof directionVariants>) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 -960 960 960"
      color="currentColor"
      className={cn(iconVariants({ size, className }))}
      {...props}
    >
      <g className={cn(directionVariants({ direction }))}>
        <path
          d="m480-320 160-160-160-160-56 56 64 64H320v80h168l-64 64 56 56Zm0 240q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
