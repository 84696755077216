export const ETH_TOKENS = [
  'ETH',
  'WETH',
  'frxETH',
  'rETH',
  'cbETH',
  'stETH',
  'wstETH',
  'ezETH',
  'weETH',
  'weETHs',
  'rsETH',
  'agETH',
  'msETH',
  'pxETH',
]

export const EURO_COINS = ['EURS', 'EURA', 'EURC']

export const STABLE_COINS = [
  'DAI',
  'MAI',
  'USDA',
  'USDC',
  'USDT',
  'USDP',
  'USDS',
  'TUSD',
  'GUSD',
  'FRAX',
  'USDbC',
  'LUSD',
  'sUSD',
  'USDM',
  'crvUSD',
  'mkUSD',
  'USDe',
  'PYUSD',
  'DOLA',
  'GHO',
  'eUSD',
  'USD0',
  'AUSD',
  'USR',
]
