import { Suspense, useMemo, useState } from 'react'

import { DataChanger, VaultsList } from './components'

import { Panel } from '@/components/atoms/Panel'
import { Page } from '@/components/molecules'
import { trpc } from '@/config/trpc'
import { ADDRESS_KEY, NETWORK_KEY } from '@/constants/stateConstants'
import { useSearchState } from '@/hooks/useSearchState'
import type { BackendTag } from '@/types'
import { fuzzySearchVaults, transformVault } from '@/utils'
import { cn } from '@/utils/cn'
import type { Address } from 'viem'
import { SearchFilter } from '../VaultsListPage/components/Filters/SearchFilter'

type VaultSearchState = { network: string; address: Address }

function ChangeVaultDataPage() {
  const [filter, setFilter] = useState('')
  const [dbVaults] = trpc.getAllVaults.useSuspenseQuery({ interval: '1day' })
  const transformedVaults = dbVaults.map(transformVault)
  const vaults = useMemo(
    () => fuzzySearchVaults(transformedVaults, filter).sort((a, b) => (a.name > b.name ? 1 : -1)),
    [transformedVaults, filter]
  )

  const { searchState, updateSearchState } = useSearchState()
  const handleVaultChange = ({ network, address }: VaultSearchState) =>
    updateSearchState([NETWORK_KEY, network], [ADDRESS_KEY, address])
  const selectedVault = useMemo(
    () => ({ network: searchState.get(NETWORK_KEY) ?? '', address: (searchState.get(ADDRESS_KEY) as Address) ?? '' }),
    [searchState]
  )

  const [vaultTags, setVaultTags] = useState<BackendTag[]>([])

  return (
    <Page title="Update data">
      <div
        className={cn(
          'grid grid-cols-[288px,1fr] gap-6',
          '[&:has([data-role=left-column]:hover)]:grid-cols-[488px,1fr] [&:has([data-role=left-column]:hover)_[data-role=vault-info]]:grid-cols-[1fr,24px,82px] [&:has([data-role=left-column]:hover)_td>div]:w-full',
          '[&:has([data-role=left-column]:focus-visible)]:grid-cols-[488px,1fr] [&:has([data-role=left-column]:focus-visible)_[data-role=vault-info]]:grid-cols-[1fr,24px,82px] [&:has([data-role=left-column]:focus-visible)_td>div]:w-full'
        )}
      >
        <div className="flex h-fit flex-col gap-6" data-role="left-column">
          <SearchFilter className="w-full" onSearchChange={setFilter} searchValue={filter} />
          <VaultsList selectedVault={selectedVault} setSelectedVault={handleVaultChange} vaults={vaults} />
        </div>
        <Panel className="flex flex-col p-4">
          {!selectedVault.address ? (
            <p>
              <b>Please select vault</b>
            </p>
          ) : (
            <Suspense
              fallback={
                <p>
                  <b>Loading vault...</b>
                </p>
              }
            >
              <DataChanger selectedVault={selectedVault} vaultTags={vaultTags} setVaultTags={setVaultTags} />
            </Suspense>
          )}
        </Panel>
      </div>
    </Page>
  )
}

export const Component = () => ChangeVaultDataPage()
