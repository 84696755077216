import { DAY_IN_SECONDS, getProtocolKey, truncateChartData } from '@vaultsfyi/common'
import type { BackendProtocol } from '../../types'
import type { MultiChartData } from './composeMultiChartData'

export function composeProtocolTvlChartData(protocolData: NonNullable<BackendProtocol>) {
  const multiChartData: MultiChartData[] = []
  const dataRecord: Record<number, MultiChartData> = {}
  for (const version of Object.values(protocolData.versions)) {
    for (const dataPoint of version.historicalData) {
      const date = dataPoint.date
      if (!dataRecord[date]) dataRecord[date] = { date }
      dataRecord[date][`tvl-${getProtocolKey(version.protocol)}`] = dataPoint.tvl
    }
  }

  for (const dataPoint of Object.values(dataRecord)) {
    multiChartData.push(dataPoint)
  }

  const daily = truncateChartData(multiChartData, 'date', DAY_IN_SECONDS)

  return {
    highResolution: multiChartData,
    daily,
  }
}
