import { cn } from '@/utils/cn'
import type { ImgHTMLAttributes } from 'react'

export interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  fallback?: string
}

export const Image = ({ src, fallback, alt, className, ...props }: ImageProps) => {
  return (
    <span
      className={cn('flex size-6 items-center justify-center overflow-hidden rounded-full', className)}
      data-role="image-wrapper"
    >
      <object data={src} type="image/png" className="h-full max-w-full object-contain">
        {/* biome-ignore lint/a11y/useAltText: <explanation> */}
        <img src={fallback} alt={alt} {...props} />
      </object>
    </span>
  )
}
