import { BookmarkedVaultsListFallback } from './BookmarkedVaultsListFallback'

import { VaultsTable } from '@/components/organisms/VaultsTable'
import { useSortedVaults, useVaultsForList, useVaultsSortState } from '@/hooks'
import { useBookmarks } from '@/providers/BookmarksProvider'
import type { ApyModes, BackendVault } from '@/types'
import type { Subdomain } from '@/types/Subdomain'
import { isAddressEqual } from 'viem'

interface BookmarkedVaultsListProps {
  vaults: BackendVault[]
  subdomain: Subdomain | undefined
  apyMode: ApyModes
}

export const BookmarkedVaultsList = ({ vaults, subdomain, apyMode }: BookmarkedVaultsListProps) => {
  const { bookmarks } = useBookmarks()
  const { sortState } = useVaultsSortState()
  const vaultsForList = useVaultsForList(vaults)
  const bookmarkedVaults = vaultsForList.filter((vault) =>
    bookmarks.some((bookmark) => isAddressEqual(bookmark.address, vault.address) && bookmark.network === vault.network)
  )
  const sortedVaults = useSortedVaults(bookmarkedVaults, sortState)

  if (bookmarkedVaults.length === 0) {
    return <BookmarkedVaultsListFallback />
  }
  return <VaultsTable vaults={sortedVaults} subdomain={subdomain} apyMode={apyMode} />
}
