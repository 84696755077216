import type { BackendVault } from '@/types'
import { getChartColor } from '@/utils'
import { type Asset, type VaultsBenchmark, getBenchmarkKey, getVaultKey } from '@vaultsfyi/common'
import { useMemo } from 'react'

interface ChartDescriptorsProps {
  key: 'tvl' | 'tvlNative' | 'apy' | 'apyWithRewards'
  vaults: BackendVault[]
  benchmarks: VaultsBenchmark[]
  hiddenDescriptors?: string[]
}

type ChartDescriptorAsset = {
  assetAddress?: Asset['assetAddress']
  decimals?: Asset['decimals']
  name?: Asset['name']
  symbol: Asset['symbol']
}

export type ChartDescriptor = {
  key: string
  name: string
  colors: string
  hidden?: boolean
  asset?: ChartDescriptorAsset
}

export const useChartDescriptors = ({
  key,
  vaults,
  benchmarks,
  hiddenDescriptors,
}: ChartDescriptorsProps): ChartDescriptor[] => {
  const chartDescriptors: ChartDescriptor[] = useMemo(() => {
    const vaultDescriptors = vaults.map((vault, index) => {
      const vaultKey = getVaultKey(vault, key)
      const vaultsSharingName = vaults.filter((v) => v.name === vault.name)
      const name = vaultsSharingName.length > 1 ? `${vault.name} (${vault.network})` : vault.name

      return {
        key: vaultKey,
        name: name,
        colors: getChartColor(index),
        hidden: hiddenDescriptors?.includes(vaultKey),
        asset: vault.asset,
      }
    })
    const benchmarkDescriptors = benchmarks.map((benchmark, index) => {
      const benchmarkKey = getBenchmarkKey(benchmark, key)
      return {
        key: benchmarkKey,
        name: benchmark.name,
        colors: getChartColor(vaults.length + index),
        hidden: hiddenDescriptors?.includes(benchmarkKey),
        asset: benchmark.asset,
      }
    })
    return [...vaultDescriptors, ...benchmarkDescriptors]
  }, [key, vaults, benchmarks, hiddenDescriptors])

  return chartDescriptors
}
