import type { ImageGetterType, SetSelectedItemsType } from '../common/types'
import type { ItemsGroup } from './SelectFilterByGroup'

import { DropdownItem, DropdownItemContent, DropdownItemText, DropdownListItem } from '@/components/atoms/Dropdown'
import { Image } from '@/components/atoms/Image'

interface Props {
  item: string
  itemsGroup: ItemsGroup
  selectedItems: string[]
  setSelectedItems: SetSelectedItemsType
  imageGetter: ImageGetterType
  name: string
  allowMultipleGroupsSelection?: boolean
}

export const SelectItem = ({
  item,
  itemsGroup,
  selectedItems,
  setSelectedItems,
  imageGetter,
  name,
  allowMultipleGroupsSelection,
}: Props) => {
  const isItemSelected = selectedItems.includes(item)

  const handleItemClick = itemsGroup.isSelectable
    ? () =>
        handleGroupItemClick(
          item,
          isItemSelected,
          selectedItems,
          setSelectedItems,
          itemsGroup.items,
          Boolean(allowMultipleGroupsSelection)
        )
    : () =>
        handleSingleItemClick(
          item,
          isItemSelected,
          selectedItems,
          setSelectedItems,
          Boolean(allowMultipleGroupsSelection)
        )

  return (
    <DropdownListItem>
      <DropdownItem onClick={handleItemClick} isActive={isItemSelected}>
        <DropdownItemContent>
          <Image
            src={imageGetter(item)}
            fallback={imageGetter('fallback')}
            alt={`${item} ${name} token image`}
            loading="lazy"
            className="min-w-6"
          />
          <DropdownItemText>{item}</DropdownItemText>
        </DropdownItemContent>
      </DropdownItem>
    </DropdownListItem>
  )
}

function handleGroupItemClick(
  item: string,
  isItemSelected: boolean,
  selectedItems: string[],
  setSelectedItems: SetSelectedItemsType,
  groupItems: string[],
  allowMultipleGroupsSelection: boolean
) {
  const selectedItemsFromGroup = selectedItems.filter((itemInArray) => groupItems.includes(itemInArray))
  const newSelectedItems = isItemSelected
    ? removeItem(selectedItems, item)
    : allowMultipleGroupsSelection
      ? addItem(selectedItems, item)
      : addItem(selectedItemsFromGroup, item)

  setSelectedItems(newSelectedItems)
}

const removeItem = (arr: string[], item: string) => arr.filter((itemInArray) => itemInArray !== item)
const addItem = (arr: string[], item: string) => (arr.includes(item) ? arr : [...arr, item])

function handleSingleItemClick(
  item: string,
  isItemSelected: boolean,
  selectedItems: string[],
  setSelectedItems: SetSelectedItemsType,
  allowMultipleGroupsSelection: boolean
) {
  if (allowMultipleGroupsSelection) {
    return setSelectedItems(isItemSelected ? removeItem(selectedItems, item) : addItem(selectedItems, item))
  } else {
    return setSelectedItems(isItemSelected ? [] : [item])
  }
}
