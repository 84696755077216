import type { Network } from '@vaultsfyi/common'

import { shortenString } from '../addressUtils'
import { getExplorerAddressLink } from '../chainUtils'
import { formatAmount } from '../valueUtils'

import type { BackendVaultDetailed } from '@/types'

export interface HoldersChartData {
  label: string
  value: number
  proportion: number
  explorerLink: string | undefined
  color: string
}

const CHART_COLORS = {
  blue: 'var(--chart-blue)',
  turquoise: 'var(--chart-turquoise)',
  purple: 'var(--chart-purple)',
  pink: 'var(--chart-pink)',
  peach: 'var(--chart-peach)',
  cyan: 'var(--chart-cyan)',
  sky: 'var(--chart-sky)',
  zinc: 'var(--chart-zinc)',
  red: 'var(--chart-red)',
  amber: 'var(--chart-amber)',
  emerald: 'var(--chart-emerald)',
  indigo: 'var(--chart-indigo)',
  fuchsia: 'var(--chart-fuchsia)',
  rose: 'var(--chart-rose)',
  green: 'var(--chart-green)',
}

const TYPED_COLORS = CHART_COLORS as { [key: string]: string }
const COLOR_KEYS = Object.keys(TYPED_COLORS)

export const getHoldersChartData = ({
  topHolders,
  holdersCount,
  holdersTotalBalance,
  tvl,
  tvlInUsd,
  network,
  asset,
}: Pick<
  BackendVaultDetailed,
  'topHolders' | 'holdersCount' | 'holdersTotalBalance' | 'tvl' | 'network' | 'asset' | 'tvlInUsd'
>): HoldersChartData[] => {
  if (!topHolders) return []
  const numericTvl = Number(tvl)
  const numericTvlInUsd = Number(tvlInUsd)
  const totalBalance = BigInt(holdersTotalBalance ?? '0')
  const topHoldersSupplyPart = topHolders.reduce((total, { balance }) => BigInt(total) + BigInt(balance), 0n)
  const topHoldersProportions = topHolders
    .map(({ address, balance }) => {
      const holderProportion = (Number(balance) / Number(totalBalance)) * 100
      return {
        totalBalance: totalBalance,
        balance: balance,
        label: shortenString(address),
        value: (holderProportion * numericTvl) / 100 / 10 ** asset.decimals,
        valueInUsd: (holderProportion / 100) * numericTvlInUsd,
        proportion: holderProportion,
        explorerLink: getExplorerAddressLink(address, network as Network),
      }
    })
    .sort((a, b) => b.value - a.value)

  const restHoldersCount = formatAmount(holdersCount ? holdersCount - topHolders.length : 0)
  const restHoldersBalance = totalBalance - topHoldersSupplyPart
  const restHoldersProportion = ((Number(totalBalance) - Number(topHoldersSupplyPart)) / Number(totalBalance)) * 100

  const restHoldersProportions = {
    label: `Rest ${restHoldersCount} holders`,
    value: Number(restHoldersBalance) / 10 ** asset.decimals,
    valueInUsd: (restHoldersProportion / 100) * numericTvlInUsd,
    proportion: restHoldersProportion,
    explorerLink: undefined,
  }

  const holdersChartData: HoldersChartData[] = [...topHoldersProportions, restHoldersProportions].map(
    (dataPoint, index) => ({
      ...dataPoint,
      asset,
      color: getHolderColor(index),
    })
  )

  return holdersChartData
}

function getHolderColor(index: number) {
  const color = COLOR_KEYS[index % COLOR_KEYS.length]
  return `hsl(${TYPED_COLORS[color]})`
}
