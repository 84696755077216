import { Image } from '@/components/atoms/Image'
import { ScrollArea } from '@/components/atoms/ScrollArea'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/atoms/Table'
import { DescribedImage } from '@/components/molecules/DescribedImage'
import type { BackendVault } from '@/types'
import { cn } from '@/utils/cn'
import { getNetworkImage, getTokenImage } from '@vaultsfyi/common'
import { type Address, isAddress, isAddressEqual } from 'viem'

interface VaultsListProps {
  vaults: BackendVault[]
  selectedVault: { network: string; address: Address }
  setSelectedVault: (vault: { network: string; address: Address }) => void
}

export function VaultsList({ vaults, selectedVault, setSelectedVault }: VaultsListProps) {
  return (
    <ScrollArea className="h-80 rounded-2xl border md:h-[624px]">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="px-2">
              <p>Vault</p>
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {vaults.map(({ name, address, network, asset }, index) => (
            <TableRow
              className="cursor-pointer"
              key={`${address}-${network}-${index}`}
              onClick={() => setSelectedVault({ address: address, network: network })}
            >
              <TableCell
                className={cn(
                  'px-2 transition-all',
                  isAddress(selectedVault.address) &&
                    isAddressEqual(address, selectedVault.address) &&
                    network === selectedVault.network &&
                    'bg-primary/15'
                )}
              >
                <div className="grid grid-cols-[148px,24px,82px] items-center gap-2" data-role="vault-info">
                  <span className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap" title="name">
                    {name}
                  </span>
                  <Image src={getNetworkImage(network)} alt={network} loading="lazy" />
                  <DescribedImage
                    src={getTokenImage(asset.symbol)}
                    fallback={getTokenImage('fallback')}
                    alt={`${asset.symbol} token image`}
                  >
                    <span>{asset.symbol}</span>
                  </DescribedImage>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ScrollArea>
  )
}
