import { DescribedImage } from '@/components/molecules/DescribedImage'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import type { BackendVaultDetailed, VaultMetadata } from '@/types'
import { getTokenImage } from '@vaultsfyi/common'

interface Props {
  asset: BackendVaultDetailed['asset']
  metadata: VaultMetadata
  tokenPrice?: number
}

export const TokenStatistic = ({ asset }: Props) => {
  const { symbol } = asset
  return (
    <div>
      <LabeledValueHeader>Token</LabeledValueHeader>
      <LabeledValueContent>
        <LabeledValueValue>
          <DescribedImage
            src={getTokenImage(symbol)}
            fallback={getTokenImage('fallback')}
            alt={`${symbol} token image`}
            loading="lazy"
          >
            <span>{symbol}</span>
          </DescribedImage>
        </LabeledValueValue>
      </LabeledValueContent>
    </div>
  )
}
