import { Image, type ImageProps } from '@/components/atoms/Image'
import { cn } from '@/utils/cn'

export const DescribedImage = ({ className, children, ...props }: ImageProps) => {
  return (
    <div className={cn('flex w-fit items-center gap-2', className)}>
      <Image {...props} />
      {children}
    </div>
  )
}
