import { DescribedImage } from '@/components/molecules/DescribedImage'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import type { BackendVaultDetailed } from '@/types'
import { getNetworkImage } from '@vaultsfyi/common'

interface Props {
  network: BackendVaultDetailed['network']
}

export const NetworkStatistic = ({ network }: Props) => {
  return (
    <div>
      <LabeledValueHeader>Network</LabeledValueHeader>
      <LabeledValueContent>
        <LabeledValueValue>
          <DescribedImage src={getNetworkImage(network)} alt={`${network} network image`} loading="lazy">
            <span className="first-letter:uppercase">{network}</span>
          </DescribedImage>
        </LabeledValueValue>
      </LabeledValueContent>
    </div>
  )
}
