import { getImageUrl } from './getImageUrl'

export function getProtocolImage(protocol: string) {
  switch (protocol.toLowerCase()) {
    case 'ether.fi':
      return getImageUrl('protocols', 'etherfi')
    case 'inverse finance':
      return getImageUrl('protocols', 'inverse')
    case 'open eden':
      return getImageUrl('protocols', 'openeden')
    case 'overnight finance':
      return getImageUrl('protocols', 'overnight')
    case 'rocket pool':
      return getImageUrl('protocols', 'rocketPool')
    case 'revert.finance':
      return getImageUrl('protocols', 'revertFinance')
    default:
      return getImageUrl('protocols', protocol)
  }
}
