import { BENCHMARKS, formatBips } from '@vaultsfyi/common'
import { isAddressEqual } from 'viem'
import type { BackendVault } from '../types'

interface BenchmarkBadgeData {
  label: string
  value: string
}

export function getLatestBenchmarks(vaults: BackendVault[]) {
  const result: BenchmarkBadgeData[] = []
  for (const [benchmarkSymbol, benchmark] of Object.entries(BENCHMARKS)) {
    let apySum = 0
    let tvlSum = 0
    for (const vault of benchmark.vaults) {
      const vaultData = vaults.find((v) => isAddressEqual(v.address, vault.address) && v.network === vault.network)
      if (vault) {
        const tvl = vaultData?.tvlInUsd ?? 0
        apySum += (vaultData?.apy ?? 0) * tvl
        tvlSum += tvl
      }
    }
    result.push({
      label: benchmarkSymbol,
      value: formatBips(Math.round(apySum / tvlSum)),
    })
  }
  return result
}
