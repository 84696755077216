import type { Subdomain } from '@/types/Subdomain'
import { SubdomainType } from '@/types/Subdomain'
import { getNetworkImage, getProtocolImage } from '@vaultsfyi/common'

export function getSubdomainImage(subdomain: Subdomain) {
  switch (subdomain.type) {
    case SubdomainType.Network:
      return getNetworkImage(subdomain.name)
    case SubdomainType.Protocol:
      return getProtocolImage(subdomain.name)
  }
}
