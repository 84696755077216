export const VAULTS_FYI_URL = 'https://vaults.fyi/'
export const APP_VAULTS_FYI_URL = 'https://app.vaults.fyi'
export const VAULTS_FYI_ARBITRUM_URL = 'https://arbitrum.vaults.fyi/'
export const VAULTS_FYI_BASE_URL = 'https://base.vaults.fyi/'
export const VAULTS_FYI_OPTIMISM_URL = 'https://op.vaults.fyi/'
export const VAULTS_FYI_POLYGON_URL = 'https://polygon.vaults.fyi/'
export const BACKEND_URL = import.meta.env['VITE_BACKEND_URL'] ?? ''
export const VAULTS_FYI_API_URL = 'https://api.vaults.fyi/'
export const VAULTS_FYI_DOCS_URL = 'https://docs.vaults.fyi'
export const VAULTS_FYI_TWITTER_URL = 'https://twitter.com/vaultsfyi'
export const VAULTS_FYI_FARCASTER_URL = 'https://warpcast.com/vaultsfyi'
export const VAULTS_FYI_TELEGRAM_URL = 'https://t.me/vaultsfyisupport'
