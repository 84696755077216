import { ChartTooltip } from '@/components/atoms/Charts/ChartTooltip'
import { DescribedImage } from '@/components/molecules/DescribedImage'
import { LabeledValueContent, LabeledValueHeader, LabeledValueValue } from '@/components/molecules/LabeledValue'
import { formatNumber } from '@/utils'
import { getTokenImage } from '@vaultsfyi/common'

export const HoldersChartTooltip = (props: Record<string, any>) => {
  const { payload, tvlInNativeAsset } = props

  if (!payload) {
    return <></>
  }

  return (
    <ChartTooltip>
      {payload.map((item: Record<string, any>, index: number) => {
        const key = item.dataKey
        const { asset, proportion, label, value, payload } = item.payload

        return (
          <div className="text-text-secondary" key={key + index}>
            <LabeledValueHeader className="!text-xs">{label}</LabeledValueHeader>
            <LabeledValueContent>
              <LabeledValueValue className="!text-base">
                <DescribedImage
                  src={getTokenImage(asset.symbol)}
                  fallback={getTokenImage('fallback')}
                  alt={`${asset.symbol} token image`}
                >
                  {tvlInNativeAsset
                    ? `${formatNumber(value)} ${asset.symbol} (${formatNumber(proportion)}%)`
                    : `$${formatNumber(payload.valueInUsd)} (${formatNumber(proportion)}%)`}
                </DescribedImage>
              </LabeledValueValue>
            </LabeledValueContent>
          </div>
        )
      })}
    </ChartTooltip>
  )
}
