import { TableCell, type TableCellProps } from '@/components/atoms/Table'
import { BookmarkButton } from '@/components/molecules/BookmarkButton'
import { type Bookmark, useBookmarks } from '@/providers/BookmarksProvider'
import { isAddressEqual } from 'viem'

export const BookmarkCell = ({ address, network, ...props }: Bookmark & TableCellProps) => {
  const { bookmarks, addBookmark, removeBookmark } = useBookmarks()
  const isBookmarked = bookmarks.some(
    (bookmark) => isAddressEqual(bookmark.address, address) && bookmark.network === network
  )
  const handleBookmark = () => {
    if (isBookmarked) {
      removeBookmark({ address, network })
    }
    if (!isBookmarked) {
      addBookmark({ address, network })
    }
  }

  return (
    <TableCell data-cell="Bookmark" {...props}>
      <BookmarkButton onClick={handleBookmark} isActive={isBookmarked} />
    </TableCell>
  )
}
